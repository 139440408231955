@import '_mixins/font-face';

//Regular fonts
@include font-face('ProximaNova', './fonts/ProximaNova-Thin', 200);
@include font-face('ProximaNova', './fonts/ProximaNova-Light', 300);
@include font-face('ProximaNova', './fonts/ProximaNova-Regular', 400);
@include font-face('ProximaNova', './fonts/ProximaNova-Semibold', 600);
@include font-face('ProximaNova', './fonts/ProximaNova-Bold', 700);
@include font-face('ProximaNova', './fonts/ProximaNova-Extrabld', 800);
@include font-face('ProximaNova', './fonts/ProximaNova-Black', 900);

//Italic fonts
@include font-face('ProximaNovaIt', './fonts/ProximaNova-ThinIt', 200);
@include font-face('ProximaNovaIt', './fonts/ProximaNova-LightIt', 300);
@include font-face('ProximaNovaIt', './fonts/ProximaNova-RegularIt', 400);
@include font-face('ProximaNovaIt', './fonts/ProximaNova-SemiboldIt', 600);
@include font-face('ProximaNovaIt', './fonts/ProximaNova-BoldIt', 700);
@include font-face('ProximaNovaIt', './fonts/ProximaNova-ExtrabldIt', 800);
@include font-face('ProximaNovaIt', './fonts/ProximaNova-BlackIt', 900);

.ContactsPage {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  background-color: var(--background); }
.ContactsPage-Container {
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 32px;
  .SectionTitle {
    grid-column: 1/-1;
    margin-bottom: 0; } }

@media screen and ( max-width: 1200px ) {
  .ContactsPage-Container {
    grid-template-columns: 400px 1fr;
    grid-gap: 24px; } }
@media screen and ( max-width: 768px ) {
  .ContactsPage-Container {
    grid-template-columns: 1fr;
    .Contacts-Map {
      order: 1; }
    .Contacts-Text {
      order: 0; } } }

.ClassFilter {
  display: flex;
  list-style-type: disc;
  li {
    margin-right: 42px;
    padding-left: 12px;
    font-size: 18px;
    cursor: pointer;
    color: var(--darkDescription);
    font-weight: 500;
    &:first-child {
      padding-left: 0;
      list-style: none; }
    &:last-child {
      margin-right: 0; }
    &:hover {
      .Title {
        color: var(--accent); } } }
  .ActiveClass {
    color: var(--black);
    &::after {
      content: '';
      display: block;
      padding-bottom: 4px;
      border-bottom: 2px var(--accent) solid; } } }

@media screen and ( max-width: 576px ) {
  .ClassFilter {
    display: grid;
    list-style-type: none;
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
    grid-gap: 16px;
    li {
      margin-right: 0;
      padding: 4px 8px;
      border: 2px var(--lightGray) solid;
      border-radius: 8px;
      &:first-child {
        padding-left: 8px; } }

    .ActiveClass {
      &::after {
        display: none; } }
    li:has(.ActiveClass) {
      color: var(--black);
      border: 2px var(--accent) solid; } } }

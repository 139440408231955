.PlacePicker {
  .PickOption {
    align-items: start; }
  .PlacePicker-Container {
    margin-top: 48px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    .Fields {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 24px; } } }

@media screen and ( max-width: 500px ) {
  .PlacePicker {
    .PlacePicker-Container {
      .Fields {
        grid-template-columns: 1fr; } } } }

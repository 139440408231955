.StatusMessage {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border-radius: 8px; }

.StatusMessage_theme_success {
  background: var(--successColor);
  color: #27C043; }

.StatusMessage_theme_fail {
  background: var(--failColor);
  color: var(--notyColor); }

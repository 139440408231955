.PhoneChooser {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  align-items: center;
  align-content: center;
  grid-gap: 12px;
  position: relative;
  cursor: pointer;
  .SocIcons {
    justify-self: center;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 12px; }
  .ChooserIcon {
    color: var(--accent);
    margin-right: 8px; }
  .Viber {

    color: #9175F0; }
  .Whatsapp {
    color: #1AD86C; }
  .Telegram {
    color: #2ba2de; }
  .Icon {
    font-size: 18px; }
  .PhoneChooser-ActivePhone {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 8px; }
  .PhoneChooser-Container {
    min-width: 48px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    padding: 8px 16px;
    position: absolute;
    top: 42px;
    right: 0;
    z-index: 11;
    box-shadow: var(--boxShadow);
    a {
      color: var(--black);
      &:hover {
        color: var(--accent) !important; } } }
  .PhoneChooser-Phone {
    text-align: center;
    padding: 4px 8px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      color: #fff; } } }

@media screen and ( max-width: 576px ) {
  .PhoneChooser {
    display: none; }
  .MobilePhoneChooser {
    width: 64px;
    height: 64px;
    background-color: var(--accent);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .PhoneSign {
      display: flex;
      align-items: center; }
    .PhoneIcon {
      font-size: 22px;
      margin-right: 12px; }

    .PhoneChooser-Container {
      min-width: 100vw !important;
      top: 64px;
      min-width: 48px;
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 8px;
      padding: 8px 16px;
      position: absolute;
      justify-items: center;
      top: 64px;
      right: -24px;
      z-index: 11;
      box-shadow: var(--boxShadow);
      a {
        color: var(--black);
        &:hover {
          color: var(--accent) !important; } }
      .SocIcons {
        font-size: 18px;
        display: grid;
        grid-template-columns: repeat(3, max-content);
        grid-gap: 12px;
        justify-content: center;
        align-items: center;
        .Viber {
          color: #9175F0;
          display: block; }
        .Whatsapp {
          display: block;
          color: #1AD86C; }
        .Telegram {
          color: #2ba2de; } } } } }

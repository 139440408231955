.Services {
  background-color: var(--background);
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  .ServiceTile {
    .Tile-Head {
      background-color: rgba(233, 240, 252, 1);
      color: var(--black);
      padding: 16px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .Icon {
        font-size: 18px;
        margin-right: 12px;
        color: var(--darkGray); } }
    .Tile-Body {
      display: none;
      margin-top: 16px;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      .Video {
        width: 100%;
        height: 300px;
        border-radius: 12px; }
      .PriceTable {
        width: 100%;
        padding: 16px;
        border: 2px var(--lightGray) solid;
        border-radius: 12px;
        line-height: 22px;
        table {
          width: 100%; }
        th {
          font-size: 18px;
          color: var(--black);
          font-weight: 700; }
        td {
          padding: 8px 0px; }
        .Price {
          width: 200px;
          text-align: right; } } } }
  .Price-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px; }

  .ServiceTile_theme_active {
    .Tile-Head {
      background-color: var(--accent);
      color: #fff;
      .Icon {
        color: #fff;
        transform: rotate(90deg); } }
    .Tile-Body {
      display: grid; } } }

.Benefits {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: var(--accent);
  background-repeat: no-repeat, no-repeat;
  background-position: bottom left, bottom right;
  .STitle {
    font-size: 42px;
    color: #fff;
    font-weight: 700;
    text-align: center; } }

.Benefits-Container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 32px;
  margin-top: 48px;
  img {
    width: 128px;
    height: 128px; }
  .BenefitTile {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    color: #fff;
    justify-items: center;
    align-content: start;
    .Title {
      font-size: 21px;
      font-weight: 700; }
    .Description {
      margin-top: 16px; } } }

@media screen and ( max-width: 576px ) {
  .Benefits-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 24px; } }

@media screen and ( max-width: 450px ) {
  .Benefits {
    .STitle {
      font-size: 32px; } }

  .Benefits-Container {
    grid-template-columns: 1fr; } }

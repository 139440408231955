.Site-Hero {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  position: relative;
  .HeroBg {
    height: 100%;
    position: absolute;
    width: 650px;
    background-size: auto 500px;
    background-position: top -110px right -100px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 275px;
    border-bottom-left-radius: 150px; }
  .Offer {
    width: 540px;
    .Title {
      font-size: 42px;
      font-weight: 700;
      line-height: 52px; }
    .Description {
      font-size: 18px;
      color: var(--darkDescription);
      margin-top: 16px; }
    .Benefits {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 24px; }
    .Benefit {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      .Icon {
        color: var(--accent);
        font-size: 22px;
        margin-right: 12px;
        margin-top: 4px; } }
    .Buttons {
      margin-top: 24px;
      display: flex;
      .Button {
        &:first-child {
          margin-right: 24px; } } } } }

@media screen and ( max-width: 1200px ) {
  .Site-Hero {
    .HeroBg {
      width: 600px;
      margin-left: 300px; } } }
@media screen and ( max-width: 1024px  ) {
  .Site-Hero {
    .HeroBg {
      left: auto;
      right: 0px;
      transform: none;
      margin-left: auto;
      width: 100%;
      background-size: cover;
      background-position: bottom -120px right 0px;
      border-bottom-left-radius: 0px; }
    .Offer {
      position: relative;
      z-index: 1;
      color: #fff;
      .Description {
        color: #fff; } } } }
@media screen and ( max-width: 700px) {
  .Site-Hero {
    .HeroBg {
      background-position: bottom -70px right 0px; } } }

@media screen and ( max-width: 576px) {
  .Site-Hero {
    .HeroBg {
      background-position: center center; }
    .Offer {
      width: 100%;
      text-align: center;
      .Title {
        font-size: 32px;
        line-height: 42px; }
      .Buttons {
        justify-content: center; } } } }

.SectionTitle {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  color: var(--black);
  display: inline-block;
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase; }

.SectionTitle-Container {
  display: flex;
  justify-content: space-between; }

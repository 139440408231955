.BookingTitle {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  .Circle {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: var(--accent);
    border-radius: 100%;
    margin-right: 16px;
    flex-shrink: 0; } }

.Tarifs {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff;
  .DesktopTable {
    margin-top: 48px;
    width: 100%;
    .Button {
      height: auto; } }
  table {
    th {
      background-color: var(--accent);
      color: #fff;
      &:first-child {
        border-top-left-radius: 8px; }
      &:last-child {
        border-top-right-radius: 8px; } }
    th,td {
      padding: 4px 8px; }

    td {
      border-bottom: 1px var(--lightGray) solid; }

    .Rib {
      td {
        background-color: var(--lightGray); } } }
  .TarifsMobileTiles {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-top: 48px;

    .TarifTile {
      padding: 16px;
      border: 1px var(--lightGray) solid;
      border-radius: 8px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      .Title {
        font-size: 18px;
        font-weight: 500; }
      table {
        th {
          background-color: var(--lightGray);
          border-radius: 0;
          color: var(--darkGray); }
        th, td {
          font-size: 14px !important;
          border-bottom: 0px; } }
      .Button {
        width: 100%; } } } }
@media screen and ( max-width: 1200px ) {
  .Tarifs {
    .DesktopTable {
      .Button {
        height: auto; } } } }

@media screen and ( max-width: 768px ) {
  .Tarifs {
    .DesktopTable {
      display: none; } } }

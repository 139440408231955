.About {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff; }
.About-Container {
  display: grid;
  grid-template-columns: 450px 1fr;
  grid-gap: 32px;
  margin-top: 48px; }

.AboutImg {
  width: 450px;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px; } }

.About-Text {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  color: var(--color-tertiary-8);
  .Benefits-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    margin-top: 0;
    margin-bottom: 24px; }
  .BenLine {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 8px;
    .Icon {
      font-size: 22px;
      color: var(--accent);
      margin-top: 4px; }
    .Title {
      font-size: 16px;
      font-weight: 500; } }

  .Button {
    justify-self: start; } }

@media screen and ( max-width: 1200px ) {
  .About-Container {
    grid-template-columns: 400px 1fr;
    grid-gap: 24px; }
  .AboutImg {
    width: 400px; } }

@media screen and ( max-width: 1024px ) {
  .About-Container {
    grid-template-columns: 350px 1fr;
    grid-gap: 24px;
    .AboutImg {
      width: 100%; } } }

@media screen and ( max-width: 576px ) {
  .About-Container {
    grid-template-columns: 1fr;
    .AboutImg {
      img {
        border-radius: 0; } } } }

.CarForm {}
.Car {
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-gap: 48px;
  margin-top: 48px;
  .Data {
    align-content: start;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Title {
      font-size: 22px;
      font-weight: 700; }
    .ModelYear {
      font-weight: 400; }
    .PriceLine {
      display: flex;
      align-items: center;
      // justify-content: space-between
      font-weight: 500;
      .PriceTable {
        margin-left: 16px;
        position: relative;
        cursor: pointer;
        &:hover {
          .PriceBox {
            display: block; } } }
      .Icon {
        font-size: 22px;
        color: var(--accent); }

      .PriceBox {
        position: absolute;
        left: 0;
        top: 40px;
        display: none;
        margin-top: -16px;
        padding: 16px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: var(--boxShadow);
        z-index: 1;
        th {
          padding: 4px;
          font-size: 12px;
          font-weight: 500;
          background-color: #eaeaea; }
        td {
          padding: 4px;
          font-size: 12px;
          &:first-child {
            background-color: var(--accent);
            color: #fff; } } } }

    .Price {
      font-weight: 700;
      font-size: 22px; }
    .Transmission {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-gap: 16px; } }
  .Tile {
    position: relative; } }

@media screen and ( max-width: 576px ) {
  .Car {
    grid-template-columns: 1fr;
    .Data {
      order: 1; }
    .Tile {
      order: 0; } } }

.Booking {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff;
  background-position: bottom 300px right 0px;
  background-repeat: no-repeat; }
.Booking-Container {
  display: grid;
  grid-template-columns: 1fr 400px;
  grid-gap: 24px;
  position: relative;
  .ModuleSpinner-Container {
    grid-column: 1/-1; } }
.BookingForm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 48px; }

@media screen and ( max-width: 1024px ) {
  .Booking-Container {
    grid-template-columns: 1fr; } }

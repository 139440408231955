.WhereTo {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff;
  .DefaultContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px; }
  .DataContainer {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: 32px;
    img {
      width: 450px;
      object-fit: cover;
      object-position: center center;
      border-top-right-radius: 150px;
      border-bottom-left-radius: 150px; }
    .Text {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px; } }
  .RightImage {
    grid-template-columns: 1fr 450px;
    img {
      order: 1; }
    .Text {
      order: 0; } } }

@media screen and ( max-width: 1024px ) {
  .WhereTo {
    .DataContainer {
      grid-template-columns: 350px 1fr;
      img {
        width: 350px; } } } }

@media screen and ( max-width: 576px ) {
  .WhereTo {
    .DataContainer {
      grid-template-columns: 1fr;
      img {
        border-radius: 8px;
        order: 0; }
      .Text {
        order: 1; } }
    .RightImage {
      img {
        order: 0; }
      .Text {
        order: 1; } } } }

.PaymentMethods-Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
  margin-top: 48px;
  .StatusMessage {
    grid-column: 1/-1; } }
@media screen and ( max-width: 500px ) {
  .PaymentMethods-Container {
    grid-template-columns: 1fr; } }

.Top {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  background-color: #fff; }

.Top-Container {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-gap: 24px;
  align-content: center;
  height: 100%;
  justify-content: end; }

@media screen and ( max-width: 576px ) {
  .Top {
    display: none; } }

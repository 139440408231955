.Footer {
  padding: 48px 0px;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .Main {
    display: flex;
    justify-content: space-between; }
  .ContactBlock {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 32px;
    align-content: center;
    align-items: center; }
  .Line {
    width: 100%;
    height: 1px;
    background: #CAD0D5;
    margin: 24px 0px; }
  .Footer-InfoContainer {
    display: flex;
    justify-content: space-between;
    .Title {
      grid-column: 1/-1;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
      color: var(--black); }
    .Clinic {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-gap: 12px; }

    .LinkBlock {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      align-content: start;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      a {
        color: var(--darkGray);
        &:hover {
          color: var(--accent); } }
      &:not(:last-child) {
        margin-right: 64px; } }
    .Contacts {
      text-align: right;
      a {
        text-decoration: underline !important;
        color: var(--accent); } }
    .Address {
      display: flex;
      align-items: center;
      .Icon {
        color: var(--accent);
        font-size: 16px;
        margin-right: 12px; } }
    .BusyHours {
      font-size: 14px;
      line-height: 20px;
      color: var(--black);
      font-weight: 500;
      margin-top: 8px; } }
  .Copyrights {
    margin-top: 24px;
    font-size: 12px;
    a {
      color: var(--accent);
      text-decoration: underline !important;
      &:hover {
        text-decoration: none !important; } } } }

@media screen and ( max-width: 992px ) {
  .Footer {
    .Main {
      flex-direction: column; }
    .ContactBlock {
      margin-top: 24px;
      display: flex;
      justify-content: space-between; }
    .Footer-InfoContainer {
      .Clinic {
        grid-template-columns: 1fr; } } } }
@media screen and ( max-width: 768px) {
  .Footer {
    .Main {
      flex-direction: row; }
    .ContactBlock {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 12px;
      margin-top: 0;
      justify-items: end; } } }
@media screen and ( max-width: 576px) {
  .Footer {
    padding-top: 0;
    .Main {
      display: none; }
    // .ContactBlock
    //   margin-top: 24px
    //   text-align: left
    //   justify-items: left

    // .Footer-InfoContainer
    //   display: grid
    //   grid-template-columns: 1fr
    //   grid-gap: 24px
    //   .Clinic
    //     grid-template-columns: 1fr
    //   .Contacts
    //     text-align: left
    // .PhoneLinks
    //   .PhoneIcon
 } }    //     margin-left: 0

.TextPage {
  display: flex;
  justify-content: center;
  padding: 48px 0px;
  background-color: var(--background);
  background-repeat: no-repeat;
  background-position: top 300px left 0px; }
.TextPage-Container {
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 32px;
  .SectionTitle {
    grid-column: 1/-1;
    margin-bottom: 0; } }
.TextPageVideo {
  width: 100%;
  height: 300px; }
.TextPage-Text {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  color: var(--color-tertiary-8); }

@media screen and ( max-width: 1200px ) {
  .TextPage-Container {
    grid-template-columns: 400px 1fr;
    grid-gap: 24px; }
  .TextPageVideo {
    height: 250px; } }
@media screen and ( max-width: 992px ) {
  .TextPage-Container {
    grid-template-columns: 1fr; }
  .TextPageVideo {
    height: 300px; } }

.Doctors {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px; }
.Doctors-Container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px; }
.DoctorTile {
  .DescriptionContent {
    margin-top: 16px; }
  .CloseDoctorDescription {
    margin-top: 16px;
    margin-left: 50%;
    transform: translateX(-50%); }
  .Avatar {
    width: 100%;
    height: 300px;
    position: relative;
    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      object-position: top center; } }
  .DetailButton {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: none; }
  .Content {
    padding: 16px; }
  .Title {
    color: var(--black);
    font-size: 18px;
    font-weight: 700;
    line-height: 24px; }
  .ShortDesc {
    font-weight: 400;
    line-height: 22px;
    margin-top: 4px; }
  .Stage {
    font-size: 12px;
    line-height: 18px;
    color: #000;
    font-weight: 500;
    margin-top: 12px; }
  &:hover {
    box-shadow: var(--boxShadow);
    .Avatar {
      &::after {
        width: 100%;
        display: block;
        content: '';
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0; } }
    .DetailButton {
      display: block;
      z-index: 1; } } }

@media screen and ( max-width: 1200px ) {
  .Doctors-Container {
    grid-template-columns: repeat(4, 1fr); } }
@media screen and ( max-width: 992px ) {
  .Doctors-Container {
    grid-template-columns: repeat(3, 1fr); } }
@media screen and ( max-width: 768px ) {
  .Doctors-Container {
    grid-template-columns: repeat(2, 1fr); } }
@media screen and ( max-width: 576px ) {
  .Doctors-Container {
    grid-template-columns: 1fr; }
  .DoctorTile {
    .Avatar {
      height: 400px;
      img {
        height: 400px; } } } }

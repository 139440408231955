.LangChooser {
  display: flex;
  align-items: center;
  img {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    margin-top: -2px; }
  .LangElement {
    margin-right: 16px; } }

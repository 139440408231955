.PickOption {
  padding: 16px;
  border: 2px var(--lightGray) solid;
  border-radius: 12px;
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-gap: 16px;
  align-items: center;
  cursor: pointer;
  .Circle {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px var(--accent) solid;
    background-color: #fff;
    color: #fff; }
  .Data {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700; }
    .Description {
      color: var(--darkDescription); } }
  &:hover {
    background-color: var(--accent);
    color: #fff;
    border: 2px var(--accent) solid;
    .Circle {
      border: 2px #fff solid;
      color: var(--accent); }
    .Data {
      .Description {
        color: #fff; } } }
  .AdditionalInfo {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    font-size: 14px;
    .SubTitle {
      font-weight: 700;
      font-size: 16px; }
    .PriceLine {
      display: flex;
      justify-content: space-between; }
    .Price {
      font-weight: 700; } } }

.PickOption_status_active {
  background-color: var(--accent);
  color: #fff;
  border: 2px var(--accent) solid;
  .Circle {
    border: 2px #fff solid;
    color: var(--accent); }
  .Data {
    .Description {
      color: #fff; } } }

@media screen and ( max-width: 500px ) {
  .PickOption {
    grid-template-columns: 36px 1fr !important;
    .AdditionalInfo {
      grid-column: 1/-1; } } }

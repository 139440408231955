.Scientific {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: var(--background); }
.Scientific-Container {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 24px; }
.ScienceTile {
  padding: 16px;
  border-radius: 8px;
  border: 1px var(--lightGray) solid;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  align-content: start;
  .Video {
    height: 180px; } }

@media screen and ( max-width: 1200px ) {
  .Scientific-Container {
    grid-template-columns: repeat(3, minmax(0, 1fr)); } }
@media screen and ( max-width: 992px ) {
  .Scientific-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }
@media screen and ( max-width: 992px ) {
  .Scientific-Container {
    grid-template-columns: 1fr; }
  .ScienceTile {
    .Video {
      height: 250px; } } }

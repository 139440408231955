.Client {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-position: bottom 128px right 0px;
  background-repeat: no-repeat; }

.Client-Container {
  display: flex;
  margin-top: 48px;
  justify-content: center;
  img {
    width: 96px; }
  .ClientTile {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-content: start;
    max-width: 200px;
    &:not(:first-child) {
      margin-left: 48px; }
    .Title {
      font-size: 21px;
      font-weight: 700;
      margin-top: 16px; } } }
.Rules-Container {
  display: grid;
  grid-template: 1fr;
  grid-gap: 24px;
  margin-top: 48px;
  position: relative; }

.RuleTile {
  padding: 16px;
  box-shadow: var(--boxShadow);
  border-radius: 16px;
  background-color: #fff;
  .Circle {
    width: 32px;
    height: 32px;
    background-color: var(--accent);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 100%;
    margin-right: 12px; }
  .RuleTile-Header {
    display: flex;
    align-items: center;
    cursor: pointer; }
  .RuleTile-Body {
    margin-top: 24px;
    display: none; }

  .Title {
    font-size: 18px;
    font-weight: 700; } }
.Spoiler_status_expanded {
  .Circle {
    transform: rotate(90deg); }
  .RuleTile-Body {
    display: block; } }

@media screen and ( max-width: 450px ) {
  .Client-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    justify-items: center;
    // .STitle
    //   font-size: 32px
    .ClientTile {
      margin-left: 0 !important; } } }

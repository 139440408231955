.FeedsCarousel {
  display: flex;
  justify-content: center;
  padding: 48px 0px; }

.FeedsCarousel-Feeds {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px; }
.FeedsCarousel-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  .CloseFeedDescription {
    margin-top: 24px; } }
.FeedContainer {
  width: 1200px;
  display: grid;
  grid-template-columns: 896px 280px;
  grid-gap: 24px; }
.RatingButtons {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-gap: 16px;
  .Yandex {
    background-color: #E30000;
    color: #fff; }
  .Google {
    background-color: #0577FC;
    color: #fff; } }
.RatingButton {
  height: 24px;
  padding: 0px 12px;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 4px;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  border-radius: 4px;
  font-weight: 500;
  .Icon {
    font-size: 16px; } }
.LeaveFeedBlock {
  padding: 16px;
  background-color: var(--black);
  color: #fff;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  align-content: start;
  align-self: start; }

@media screen and ( max-width: 576px ) {
  .FeedsCarousel-Feeds {
    grid-template-columns: 1fr;
    justify-items: center; }
  .FeedContainer {
    width: 320px;
    grid-template-columns: 320px; } }

.Catalog {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff; }
.Catalog-Container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 24px;
  margin-top: 48px;
  .ModuleSpinner-Container {
    grid-column: 1/-1; }
  .EmptyCatalogMessage {
    grid-column: 1/-1;
    color: var(--darkDescription);
    text-align: center; } }
.CarTile {
  padding: 16px;
  border: 2px var(--lightGray) solid;
  border-radius: 16px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  position: relative;
  .Title {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    .ModelYear {
      font-weight: 400; } }
  .Transmission {
    text-transform: uppercase;
    font-weight: 500; }
  .TransmissionType {
    padding: 8px;
    border: 2px var(--accent) solid;
    border-radius: 8px;
    margin-left: 16px; }
  .PriceLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    .Price {
      font-weight: 700;
      font-size: 21px; } }
  &:hover {
    border: 2px var(--accent) solid; } }
.CarTags {
  position: absolute;
  top: 16px;
  left: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  .Tag {
    font-size: 18px;
    font-weight: 700;
    span {
      color: var(--accent);
      font-weight: 900; }
    .Icon {
      font-size: 18px;
      color: var(--accent);
      margin-right: 8px; } } }
.CarAvatar {
  width: 100%;
  height: 220px;
  object-fit: contain;
  object-position: top center; }

@media screen and ( max-width: 1024px ) {
  .Catalog-Container {
    grid-template-columns: repeat(2, minmax(0, 1fr)); } }

@media screen and ( max-width: 800px ) {
  .Catalog {
    .SectionTitle-Container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; } } }
@media screen and ( max-width: 576px ) {
  .Catalog-Container {
    grid-template-columns: 1fr; } }

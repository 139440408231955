.Success {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff;
  background-position: bottom 128px right 0px;
  background-repeat: no-repeat; }
.Success-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  text-align: center;
  .Title {
    font-size: 36px;
    font-weight: 700; }
  .Description {
    font-size: 18px;
    color: var(--darkDescription); }
  .Image-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    .CarImage {
      height: 350px; } } }

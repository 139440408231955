.UserForm {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 48px; }
.UserForm-Container {
  padding: 16px;
  border: 2px var(--lightGray) solid;
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  .SubTitle {
    text-transform: uppercase;
    font-weight: 700;
    grid-column: 1/-1;
    margin-bottom: 16px; }
  .StatusMessage {
    grid-column: 1/-1; } }

@media screen and ( max-width: 500px ) {
  .UserForm-Container {
    grid-template-columns: 1fr; } }

.Ur {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0px;
  background-color: #fff;
  .DataContainer {
    display: grid;
    grid-template-columns: 450px 1fr;
    grid-gap: 32px;
    margin-top: 48px;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      border-top-right-radius: 150px;
      border-bottom-left-radius: 150px; }
    .Text {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px; } } }

@media screen and ( max-width: 576px ) {
  .Ur {
    .DataContainer {
      grid-template-columns: 1fr;
      img {
        order: 0;
        border-radius: 8px; }
      .Text {
        order: 1; } } } }

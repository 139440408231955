.TotalsBlock {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  position: sticky;
  top: 128px;
  align-self: start;
  padding: 24px;
  border: 2px var(--lightGray) solid;
  border-radius: 16px;
  background-color: #FAFAFA;
  .Title {
    font-size: 22px;
    font-weight: 700; }
  p {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    span {
      flex-shrink: 0;
      margin-left: 16px;
      font-weight: 400; } }
  .Button {
    width: 100%; } }
.Notice {
  font-size: 14px;
  a {
    color: var(--accent);
    text-decoration: underline !important;
    &:hover {
      text-decoration: none !important; } } }
